import appStrings from '@/app/utility/string.utility';
import DatePicker from 'vue2-datepicker';
import { required } from 'vuelidate/lib/validators';
export default {
  name: 'addEditDeliveryChallan',
  components: { DatePicker },
  props: ['challanRowDetails'],
  data() {
    return {
      loader: false,
      isSuccess: false,
      showAlert: false,
      responseMsg: '',
      editMode: false,
      showValueSetModal: false,
      challanHdrId: 0,
      legalEntity: { text: null, value: null },
      shipLocation: { text: null, value: null },
      shipGstn: null,
      shipAddress1: null,
      shipAddress2: null,
      shipCountry: null,
      shipState: null,
      shipCity: null,
      shipPincode: null,
      // recLegalEntity: null,
      recLocation: { text: null, value: null },
      recGstn: null,
      recAddress1: null,
      recAddress2: null,
      recCountry: null,
      recState: null,
      recCity: null,
      recPincode: null,
      challanNum: null,
      challanDate: null,
      clientInvNum: null,
      dated: null,
      eWaybillNum: null,
      clientMawbn: null,
      movmntReason: 'Storage of unclaimed goods',
      supplyType: 'Not a Supply',
      delChallanData: [
        {
          // sl_num: null,key.
          challan_dtl_id: null,
          material: null,
          decs_of_goods_services: null,
          hsn_sac: null,
          quantity: null,
          price: null,
          tax_rate: 0,
          cgst: null,
          sgst: null,
          igst: null
        }
      ],
      delChallanFields: [
        { key: 'add', stickyColumn: true },
        { key: 'remove', stickyColumn: true },
        // { key: 'sl_num', label: 'Sls No.', class: 'item-col' },
        { key: 'material' },
        {
          key: 'decs_of_goods_services',
          label: 'Desc. Of Goods/Services',
          class: 'col-fix'
        },
        { key: 'hsn_sac', label: 'HSN/SAC'},
        { key: 'quantity', label: 'Quantity' },
        { key: 'price', label: 'Price(per unit)' },
        { key: 'tax_rate' },
        { key: 'cgst', label: 'CGST' },
        { key: 'sgst', label: 'SGST' },
        { key: 'igst', label: 'IGST' }
      ],
      locType: null
    };
  },
  validations: {
    legalEntity: { text: { required } },
    shipLocation: { text: { required } },
    recLocation: { text: { required } },
    dated: { required }
  },
  mounted() {
    if (this.challanRowDetails) {
      this.challanHdrId = this.challanRowDetails.challan_hdr_id;
      this.getDelChallandtlsById();
    } else {
      this.editMode = true;
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          // this.delChallanValidation()
          this.addEditDelChallanDtls();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });
  },
  methods: {
    addEditDelChallanDtls() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const checkDescHsnIsNull = this.delChallanData.filter(
          (data) => !data.decs_of_goods_services || !data.hsn_sac
        );
        if(checkDescHsnIsNull.length){
          this.delChallanValidation()
        }else{
          const challanListData = this.delChallanData.map(key => {
            return {
              challan_dtl_id: key.challan_dtl_id ? key.challan_dtl_id : 0,
              material: key.material,
              decs_of_goods_services: key.decs_of_goods_services,
              hsn_sac: key.hsn_sac,
              quantity: key.quantity,
              price: key.price,
              tax_rate: key.tax_rate ? key.tax_rate : 0,
              cgst: key.cgst ? key.cgst : 0,
              sgst: key.sgst ? key.sgst : 0,
              igst: key.igst ? key.igst : 0
            };
          });
          const payload = {
            challan_hdr_id: this.challanHdrId,
            le_id: this.legalEntity.value,
            shp_loc_id: this.shipLocation.value,
            shp_gstn: this.shipGstn,
            rec_loc_id: this.recLocation.value,
            rec_gstn: this.recGstn,
            challan_date: this.challanDate,
            client_invoice_no: this.clientInvNum,
            client_inv_date: this.dated,
            e_waybill_no: this.eWaybillNum,
            client_mawbn: this.clientMawbn,
            supply_type: this.supplyType,
            reason_for_movement: this.movmntReason,
            challan_dtl: challanListData ? challanListData : null
          };
          this.loader = true;
          this.$store
            .dispatch('assets/addEditDelChallanDtls', payload)
            .then(response => {
              this.loader = false;
              this.showAlert = true;
              if (response.status === 200) {
                this.challanHdrId = response.data.data.challan_hdr_id;
                this.getDelChallandtlsById();
                this.editMode = false;
                this.isSuccess = true;
                this.responseMsg = response.data.message;
              } else {
                this.isSuccess = false;
                this.responseMsg = response.response.data.message;
              }
            })
            .catch(() => {
              this.loader = false;
              this.showAlert = true;
              this.isSuccess = false;
            });
        }
      }
    },
    getDelChallandtlsById() {
      const payload = {
        del_challan_id: this.challanHdrId
      };
      this.loader = true;
      this.$store
        .dispatch('assets/getDelChallandtlsById', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            // if (response.data.data.length > 0) {
            const result = response.data.data;
            this.legalEntity.text = result.le_name;
            this.legalEntity.value = result.le_id;
            this.shipLocation.text = result.shp_loc_name;
            this.shipLocation.value = result.shp_loc_id;
            this.shipGstn = result.shp_gstn;
            this.shipAddress1 = result.shp_address1;
            this.shipAddress2 = result.shp_address2;
            this.shipCountry = result.shp_country;
            this.shipState = result.shp_state;
            this.shipCity = result.shp_city;
            this.shipPincode = result.shp_pin;
            this.recLocation.text = result.rec_loc_name;
            this.recLocation.value = result.rec_loc_id;
            this.recGstn = result.rec_gstn;
            this.recAddress1 = result.rec_address1;
            this.recAddress2 = result.rec_address2;
            this.recCountry = result.rec_country;
            this.recState = result.rec_state;
            this.recCity = result.rec_city;
            this.recPincode = result.rec_pin;
            this.challanNum = result.challan_no;
            this.challanDate = result.challan_date;
            this.clientInvNum = result.client_invoice_no;
            this.dated = result.client_inv_date;
            this.eWaybillNum = result.e_waybill_no;
            this.clientMawbn = result.client_mawbn;
            this.supplyType = result.supply_type;
            this.movmntReason = result.reason_for_movement;
            this.delChallanData = result.challan_dtl;
          }
          // }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    delChallanValidation(){
        const checkDescHsn = this.delChallanData.filter(
          (data) => !data.decs_of_goods_services && !data.hsn_sac
        );
        const checkHsn = this.delChallanData.filter(
          (data) =>!data.hsn_sac
        );
        const checkDesc = this.delChallanData.filter(
          (data) => !data.decs_of_goods_services
        );
        if(checkDescHsn.length){
          alert("Please enter Desc. Of Goods/Services and HSN/SAC")
        }else if(checkHsn.length){
          alert("Please enter HSN/SAC")
        }else if(checkDesc.length){
          alert("Please enter Desc. Of Goods/Services")
        }
    },
    getGstnLocation(locationId) {
      const payload = {
        locId: locationId,
        leId: this.legalEntity.value
      };
      this.loader = true;
      this.$store
        .dispatch('assets/getLocationBasedGstn', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const result = response.data.data;
            if (this.locType === 'shipLoc') {
              this.shipGstn = result;
            } else if (this.locType === 'recLoc') {
              this.recGstn = result;
            }
            this.locType = null;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getAddressDetailsById(locationId) {
      const payload = {
        loc_id: locationId
      };
      this.loader = true;
      this.$store
        .dispatch('assets/getAddressDetailsById', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const result = response.data.data;
            if (this.locType === 'shipLoc') {
              this.shipAddress1 = result.address1;
              this.shipAddress2 = result.address2;
              this.shipCountry = result.country;
              this.shipState = result.state;
              this.shipCity = result.city;
              this.shipPincode = result.pincode;
            } else if (this.locType === 'recLoc') {
              this.recAddress1 = result.address1;
              this.recAddress2 = result.address2;
              this.recCountry = result.country;
              this.recState = result.state;
              this.recCity = result.city;
              this.recPincode = result.pincode;
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    calculateTax(index, item) {
      //  this.delChallanData[index].cgst=item.tax_rate
      let calTax = (+item.price * +item.quantity * +item.tax_rate) / 100;
      if (
        this.shipGstn[0] === this.recGstn[0] &&
        this.shipGstn[1] === this.recGstn[1]
      ) {
        this.delChallanData[index].cgst = (calTax / 2).toFixed(2);
        this.delChallanData[index].sgst = (calTax / 2).toFixed(2);
        this.delChallanData = JSON.parse(JSON.stringify(this.delChallanData));
      } else {
        this.delChallanData[index].igst = calTax.toFixed(2);
        this.delChallanData = JSON.parse(JSON.stringify(this.delChallanData));
      }
    },
    checkPriceQty(index, item) {
      if (item.price && item.quantity) {
        this.calculateTax(index, item);
      } else if (item.price && !item.quantity) {
        alert('Please enter quantity');
        this.delChallanData[index].tax_rate = null;
      } else if (!item.price && item.quantity) {
        alert('Please enter price');
        this.delChallanData[index].tax_rate = null;
      } else {
        alert('Please enter price and quantity');
        this.delChallanData[index].tax_rate = null;
      }
    },
    changeData(index) {
      this.delChallanData[index].cgst = null;
      this.delChallanData[index].sgst = null;
      this.delChallanData[index].igst = null;
      this.delChallanData[index].tax_rate = null;
    },
    addNewRow() {
      if (this.editMode) {
        this.delChallanData.push({
          challan_dtl_id: null,
          material: null,
          decs_of_goods_services: null,
          hsn_sac: null,
          quantity: null,
          price: null,
          tax_rate: null,
          cgst: null,
          sgst: null,
          igst: null
        });
      }
    },
    removeRow(index) {
      this.delChallanData.splice(index, 1);
    },
    clearValuesBasedValueSet(valueSetName) {
      if (valueSetName === 'legal_entity') {
        this.shipLocation.text = null;
        this.shipLocation.value = null;
        this.shipGstn = null;
        this.shipAddress1 = null;
        this.shipAddress2 = null;
        this.shipCountry = null;
        this.shipState = null;
        this.shipCity = null;
        this.shipPincode = null;
        this.recLocation.text = null;
        this.recLocation.value = null;
        this.recGstn = null;
        this.recAddress1 = null;
        this.recAddress2 = null;
        this.recCountry = null;
        this.recState = null;
        this.recCity = null;
        this.recPincode = null;
      } else if (valueSetName === 'challan_loc') {
        if (this.locType === 'shipLoc') {
          this.shipGstn = null;
          this.shipCountry = null;
          this.shipState = null;
          this.shipCity = null;
          this.shipPincode = null;
          this.delChallanData.map(key => {
            key.tax_rate = null;
            key.cgst = null;
            key.sgst = null;
            key.igst = null;
          });
        } else if (this.locType === 'recLoc') {
          this.recGstn = null;
          this.recCountry = null;
          this.recState = null;
          this.recCity = null;
          this.recPincode = null;
          this.delChallanData.map(key => {
            key.tax_rate = null;
            key.cgst = null;
            key.sgst = null;
            key.igst = null;
          });
        }
      }
    },
    locationBasedLegalEntity(locType) {
      if (this.legalEntity.value) {
        if (locType === 'shipLoc') {
          this.openValueSetModal('CHALLAN_LOC', 'shipLoc');
        } else if (locType === 'recLoc') {
          this.openValueSetModal('CHALLAN_LOC', 'recLoc');
        }
      } else {
        alert('Please Select Legal Entity');
      }
    },
    validateHsnSac(item) {
      if (item.hsn_sac.length < 6) {
        alert("HSN Code Should between 6 to 8 digits.");
        item.hsn_sac = null;
      }
    },
    openValueSetModal(vsetCode, locType) {
      this.vsetCode = vsetCode;
      this.parent_value_set_id = null;
      this.locType = locType;
      if (this.vsetCode === appStrings.VALUESETTYPE.CHALLAN_LOC) {
        this.parent_value_set_id = this.legalEntity.value;
      }
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.legalEntity = {
          text: item.value_code,
          value: item.value_set_dtl_id
        };
        this.clearValuesBasedValueSet('legal_entity');
      } else if (this.vsetCode === appStrings.VALUESETTYPE.CHALLAN_LOC) {
        if (this.locType === 'shipLoc') {
          this.shipLocation = {
            text: item.value_code,
            value: item.value_set_dtl_id
          };
          this.clearValuesBasedValueSet('challan_loc');
          this.getGstnLocation(this.shipLocation.value);
          this.getAddressDetailsById(this.shipLocation.value);
        } else if (this.locType === 'recLoc') {
          this.recLocation = {
            text: item.value_code,
            value: item.value_set_dtl_id
          };
          this.clearValuesBasedValueSet('challan_loc');
          this.getGstnLocation(this.recLocation.value);
          this.getAddressDetailsById(this.recLocation.value);
        }
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode, locType) {
      if (vsetCode === this.legalEntity.value) {
        this.legalEntity = {
          text: null,
          value: null
        };
        this.shipLocation.text = null;
        this.shipLocation.value = null;
        this.shipAddress1 = null;
        this.shipAddress2 = null;
        this.shipGstn = null;
        this.shipCountry = null;
        this.shipState = null;
        this.shipCity = null;
        this.shipPincode = null;
        this.recLocation.text = null;
        this.recLocation.value = null;
        this.recAddress1 = null;
        this.recAddress2 = null;
        this.recGstn = null;
        this.recCountry = null;
        this.recState = null;
        this.recCity = null;
        this.recPincode = null;
      } else if (
        vsetCode === this.shipLocation.value &&
        locType === 'shipLoc'
      ) {
        this.shipLocation = {
          text: null,
          value: null
        };
        this.shipGstn = null;
        this.shipAddress1 = null;
        this.shipAddress2 = null;
        this.shipCountry = null;
        this.shipState = null;
        this.shipCity = null;
        this.shipPincode = null;
        this.delChallanData.map(key => {
          key.tax_rate = null;
          key.cgst = null;
          key.sgst = null;
          key.igst = null;
        });
      } else if (vsetCode === this.recLocation.value && locType === 'recLoc') {
        this.recLocation = {
          text: null,
          value: null
        };
        this.recGstn = null;
        this.recAddress1 = null;
        this.recAddress2 = null;
        this.recCountry = null;
        this.recState = null;
        this.recCity = null;
        this.recPincode = null;
        this.delChallanData.map(key => {
          key.tax_rate = null;
          key.cgst = null;
          key.sgst = null;
          key.igst = null;
        });
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
