import addEditDelChallan from './addEditDelChallan';
import DatePicker from 'vue2-datepicker';
import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import URL_UTILITY from '../../../utility/url.utility';
export default {
  name: 'deliveryChallan',
  watch: {
    currentPage: function() {
      this.getDelChallanDtls();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getDelChallanDtls();
    }
  },
  components: { addEditDelChallan, DatePicker },
  data() {
    return {
      loader: false,
      isSuccess: false,
      showAlert: false,
      showDelChallanModal: false,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      currentPage: 1,
      responseMsg: '',
      editMode: false,
      legalEntity: { text: null, value: null },
      recLocation: { text: null, value: null },
      shipLocation: { text: null, value: null },
      challanNum: null,
      challanDateFrom: null,
      challanDateTo: null,
      clientInvNum: null,
      invoiceDate: null,
      locType: null,
      showValueSetModal: false,
      delChallanData: [],
      delChallanFields: [
        { key: 'challan_number' },
        { key: 'legal_entity' },
        { key: 'shipper_location' },
        { key: 'receiver_location' },
        { key: 'challan_date' },
        { key: 'client_inv_date' },
        { key: 'client_invoice_num', label: 'Client Invoice Number' },
        {
          key: 'download',
          label: 'Report'
        }
      ],
      challanRowDetails: null,
      dowloadReport: URL_UTILITY.getDownloadReportUrl,
      requestId: null,
      templateId: null,
      parent_value_set_id: null
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.showDelChallanModal = true;
          this.challanRowDetails = null;
        }
      }
    });
  },
  methods: {
    getDelChallanDtls() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        le_id: this.legalEntity.value,
        ship_location: this.shipLocation.value,
        rec_location: this.recLocation.value,
        challan_num: this.challanNum,
        challan_date_from: this.challanDateFrom,
        challan_date_to: this.challanDateTo,
        client_invoice_num: this.clientInvNum,
        invoice_date: this.invoiceDate
      };
      this.loader = true;
      this.$store
        .dispatch('assets/getDelChallanDtls', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.delChallanData = resp.data.data.page.map(data => {
              data.download = 'Download';
              return data;
            });
            this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    disabledDates(date) {
      return commonHelper.disabledDates(date, this.challanDateFrom);
    },
    rowSelected(item) {
      this.challanRowDetails = item;
      this.showHideDelChallanModal(true);
    },
    showHideDelChallanModal(flag) {
      this.showDelChallanModal = flag;
    },
    locationBasedLegalEntity(locType) {
      if (this.legalEntity.value) {
        if (locType === 'shipLoc') {
          this.openValueSetModal('CHALLAN_LOC', 'shipLoc');
        } else if (locType === 'recLoc') {
          this.openValueSetModal('CHALLAN_LOC', 'recLoc');
        }
      } else {
        alert('Please Select Legal Entity');
      }
    },
    openValueSetModal(vsetCode, locType) {
      this.vsetCode = vsetCode;
      this.parent_value_set_id = null;
      this.locType = locType;
      if (this.vsetCode === appStrings.VALUESETTYPE.CHALLAN_LOC) {
        this.parent_value_set_id = this.legalEntity.value;
      }
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.legalEntity = {
          text: item.value_code,
          value: item.value_set_dtl_id
        };
        this.shipLocation = { text: null, value: null };
        this.recLocation = { text: null, value: null };
      }
      if (this.vsetCode === appStrings.VALUESETTYPE.CHALLAN_LOC) {
        if (this.locType === 'shipLoc') {
          this.shipLocation = {
            text: item.value_code,
            value: item.value_set_dtl_id
          };
        } else if (this.locType === 'recLoc') {
          this.recLocation = {
            text: item.value_code,
            value: item.value_set_dtl_id
          };
        }
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode, locType) {
      if (vsetCode === this.legalEntity.value) {
        this.legalEntity = {
          text: null,
          value: null
        };
        this.shipLocation = {
          text: null,
          value: null
        };
        this.recLocation = {
          text: null,
          value: null
        };
      } else if (
        vsetCode === this.shipLocation.value &&
        locType === 'shipLoc'
      ) {
        this.shipLocation = {
          text: null,
          value: null
        };
      } else if (vsetCode === this.recLocation.value && locType === 'recLoc') {
        this.recLocation = {
          text: null,
          value: null
        };
      }
    },
    clearFilters() {
      this.lesseeName = null;
      this.legalEntity = { value: null, text: null };
      this.recLocation = { text: null, value: null };
      this.shipLocation = { text: null, value: null };
      this.challanDateFrom = null;
      this.challanDateTo = null;
      this.totalRows = null;
      this.challanNum = null;
      this.clientInvNum = null;
      this.invoiceDate = null;
      this.currentPage = 1;
      this.delChallanData = [];
    },
    getTemplateDetails(item) {
      const payload = {
        _page: 0,
        _limit: 10,
        template_name: 'Challan Report Temp'
      };
      this.loader = true;
      this.$store
        .dispatch('assets/getTemplateDetails', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.requestId = resp.data.data.page[0].request_id;
            this.templateId = resp.data.data.page[0].template_id;
            this.saveSubmitRequest(item);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    saveSubmitRequest(item) {
      const payload = {
        admin_submit_req_details: [
          {
            request_num: 0,
            request_id: this.requestId,
            template_id: this.templateId,
            schedule_type: 'asap', //pass hardcode
            request_start_date: null,
            request_end_date: null,
            resubmit_interval: null,
            resubmit_interval_unit: null,
            day_of_month: null,
            day_of_week: null,
            output_format_id: 'PDF', //pass hardcode
            no_of_args: 25,
            request_parameter: item.challan_hdr_id,
            sms_flag: false,
            email_flag: false,
            whatsapp_flag: false,
            child_req_count: null,
            email_comm_template_id: null,
            sms_comm_template_id: null,
            whatsapp_comm_template_id: null,
            argument1: item.challan_hdr_id,
            argument2: null,
            argument3: null,
            argument4: null,
            argument5: null,
            argument6: null,
            argument7: null,
            argument8: null,
            argument9: null,
            argument10: null,
            argument11: null,
            argument12: null,
            argument13: null,
            argument14: null,
            argument15: null,
            argument16: null,
            argument17: null,
            argument18: null,
            argument19: null,
            argument20: null,
            argument21: null,
            argument22: null,
            argument23: null,
            argument24: null,
            argument25: null
          }
        ]
      };
      this.loader = true;
      this.$store
        .dispatch('template/saveSubmitRequest', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 201) {
            this.requestNumId = resp.data.data[0].id;
            this.getSubmitRequest(item);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getSubmitRequest(item) {
      this.loader = true;
      this.$store
        .dispatch('template/getSubmitRequest', this.requestNumId)
        .then(resp => {
          // this.loader = false;
          if (resp.status === 200) {
            this.requestStatus = resp.data.data[0].status;
            this.reqId = resp.data.data[0].id;
            if (this.requestStatus === 'COMPLETED') {
              this.loader = false;
              window.location.href = this.dowloadReport + '/' + this.reqId;
            } else if (this.requestStatus === 'ERROR') {
              this.loader = false;
              alert('Request in ERROR can not Preview');
            } else {
              // this.getSubmitRequest(item);
              setTimeout(() => {
                this.getSubmitRequest(item);
              }, 3000);
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    downloadReport() {
      window.location.href = this.dowloadReport + '/' + 59452;
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
